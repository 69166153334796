.order-success {
  width: 119.3px;
  height: 120.2px;
  margin: 0 141.2px 36.1px 139.5px;
  object-fit: contain;
}

.dashboard-carousel-section {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 64px;
  padding-right: 64px;
}

.yt-cart-price-lister-1 {
  padding-left: 450px;
  padding-right: 450px;
  margin-bottom: 24px;
  padding-block: 100px;
}

.cart-product-price-success {
  width: 466px;
  height: 41px;
  margin: 100px;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--color-RegularActiveText);
}

.You-havent-added-an-1 {
  width: 174px;
  height: 18px;
  margin: 6px 110px 40px;
  font-family: GTWalsheimPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c213a;
}

.cart-product-price-reject {
  width: 466px;
  height: 41px;
  font-family: GTWalsheimPro;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f67054;
}

.cart-product-price-1 {
  width: 246px;
  height: 18px;
  margin: 6px 77.5px 40px 76.5px;
  font-family: GTWalsheimPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c213a;
}

.cart-product-price-2 {
  width: 86px;
  height: 41px;
  margin: 0px 0px 0px 0px;
  font-family: GTWalsheimPro;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: normal;
  text-align: center;
  color: #30458c;
  /* padding: 0px 150px 0px 150px; */
}

.cart-divider {
  width: 400px;
  height: 1px;
  margin: 40px 0;
  background-color: #dbdbdb;
}

.track-button {
  width: 180px;
  height: 50px;
  margin: 40px 110.5px 0 109.5px;
  padding: 15px 42px;
  border-radius: 6px;
  /* background-color: #3f7a4f; */
  background-color: var(--button-primary);
  color: var(--button-text-primary);
}

.track-button:hover {
  background-color: var(--button-primary-hover);
  color: var(--button-text-primary-hover);
}

.track-button-1 {
  width: 180px;
  height: 50px;
  margin: 18px -39.5px 0 55.5px;
  padding: 15px 42px;
  border-radius: 6px;
  background-color: var(--button-primary);
  color: var(--button-text-primary);
  /* background-color: #3f7a4f; */
}

.track-button-1:hover {
  background-color: var(--button-primary-hover);
  color: var(--button-text-primary-hover);
}

.cart-product-price-3 {
  width: 178px;
  height: 16px;
  /* margin: 7px 111.5px 40px 85px; */
  font-family: GTWalsheimPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7c8188;
}

@media (max-width: 768px) {
  .dashboard-carousel-section {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-tile-group .input-container {
  position: relative;
  height: 7rem;
  width: 100%;
  margin: 10px 10px;
}

.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #e2e2e2;
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
}

.radio-tile-group .input-container .icon svg {
  fill: #079ad9;
  width: 3rem;
  height: 3rem;
}

.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #079ad9;
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile {
  /* background-color: #079ad9; */
  border: 2px solid var(--button-primary-hover);
  color: black;
  /* transform: scale(1.1, 1.1); */
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile .radio-tile-label {
  color: white;
  background-color: #079ad9;
}

.address-content {
  height: 100px;
  overflow: auto;
}